@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
	margin: 0;
	padding: 0;
}

.button {
	position: absolute;
	left: 50%;
	top: 0%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: 0.5s ease;
}

.button-div:hover > .button {
	/* visibility: visible; */
	opacity: 1;
	top: 45%;
	left: 50%;
}
